import Link from 'next/link';
import styled from '@emotion/styled';
import {PolarSignalsFull} from '@polarsignals/icons';

const FooterContainer = styled.footer`
  background: linear-gradient(91.7deg, #8cd5ef 0%, #b5e8d6 100%);
  padding: 50px 0;
`;

const Column = styled.div`
  ul {
    list-style: none;
    padding-left: 0;
    padding-top: 0.5rem;
  }

  li {
    line-height: 2.5rem;
  }

  :last-child {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    padding: 10px;
  }

  @media (max-width: 640px) {
    padding: 10px 5px;
    margin-bottom: 50px;
  }
`;

const FooterHeader = styled.div`
  font-weight: bold;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <div className="container mx-auto px-4">
        <div className="relative grid grid-cols-12 gap-4 gap-y-16">
          <Column className="col-span-6 lg:col-span-2">
            <FooterHeader>Company</FooterHeader>
            <ul>
              <li>
                <Link href="/blog">Blog</Link>
              </li>
              <li>
                <Link href="/tos">Terms of Service</Link>
              </li>
              <li>
                <Link href="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link href="/working-at-polar-signals/">Working at Polar Signals</Link>
              </li>
            </ul>
          </Column>

          <Column className="col-span-6 lg:col-span-2">
            <FooterHeader>Product</FooterHeader>
            <ul>
              <li>
                <a target="_blank" rel="noreferrer" href="https://share.polarsignals.com/">
                  Share Profiles
                </a>
              </li>
              <li>
                <Link href="/#faq">FAQ</Link>
              </li>
              <li>
                <Link href="mailto:support@polarsignals.com">Support</Link>
              </li>
              <li>
                <Link href="mailto:hello@polarsignals.com">Contact</Link>
              </li>
            </ul>
          </Column>

          <Column className="col-span-6 lg:col-span-2">
            <FooterHeader>Social</FooterHeader>
            <ul>
              <li>
                <a target="_blank" rel="noreferrer" href="https://twitter.com/PolarSignalsIO">
                  Twitter
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href="https://github.com/polarsignals">
                  GitHub
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href="https://discord.gg/knw3u5X9bs">
                  Discord
                </a>
              </li>
            </ul>
          </Column>

          <Column className="col-span-6">
            <div className="w-max">
              <PolarSignalsFull width="auto" />
            </div>
          </Column>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
