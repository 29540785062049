import {Fragment} from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';
import {Popover, Transition} from '@headlessui/react';
import {Hamburger, PolarSignals} from '@polarsignals/icons';

const NavbarContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 0;
  font-size: 14px;

  a,
  a:visited {
    color: white !important;
  }
`;

const NavbarLink = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  margin: 0 25px;

  :last-child {
    margin-right: 0;
  }

  .cta {
    background-color: white;
    border-radius: 12px;
    padding: 7px 40px;
    text-transform: uppercase;
    color: #4c68f6;
    font-weight: 900;
  }
`;

const Navbar = () => {
  return (
    <Popover className="relative bg-white">
      <NavbarContainer>
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between">
            <Link href="/">
              <a>
                <PolarSignals />
              </a>
            </Link>

            <div className="-my-2 -mr-2 lg:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 hover:opacity-80">
                <span className="sr-only">Open menu</span>
                <Hamburger width={20} height={20} />
              </Popover.Button>
            </div>

            <Transition
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition lg:hidden"
              >
                <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="px-5 pt-5 pb-6">
                    <div className="absolute top-2 right-6 flex items-center justify-end">
                      <div className="-mr-2">
                        <Popover.Button className="focus:none inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                          <span className="sr-only">Close menu</span>
                          <span className="text-4xl">&times;</span>
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-y-8">
                        <Popover.Button as="div">
                          <Link href="/#open-source">
                            <a className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50">
                              <span className="ml-3 text-base font-medium text-gray-900">
                                Open Source
                              </span>
                            </a>
                          </Link>
                        </Popover.Button>
                        <Popover.Button as="div">
                          <Link href="/#why">
                            <a className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50">
                              <span className="ml-3 text-base font-medium text-gray-900">
                                Product
                              </span>
                            </a>
                          </Link>
                        </Popover.Button>
                        <Popover.Button as="div">
                          <Link href="/about-us">
                            <a className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50">
                              <span className="ml-3 text-base font-medium text-gray-900">
                                About Us
                              </span>
                            </a>
                          </Link>
                        </Popover.Button>
                        <Popover.Button as="div">
                          <Link href="/working-at-polar-signals/">
                            <a className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50">
                              <span className="ml-3 text-base font-medium text-gray-900">
                                Careers
                              </span>
                            </a>
                          </Link>
                        </Popover.Button>
                        <Popover.Button as="div">
                          <Link href="/blog">
                            <a className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50">
                              <span className="ml-3 text-base font-medium text-gray-900">Blog</span>
                            </a>
                          </Link>
                        </Popover.Button>
                      </nav>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>

            <div className="lg:display hidden lg:block lg:flex lg:items-center">
              <NavbarLink>
                <Link href="/#open-source">Open Source</Link>
              </NavbarLink>
              <NavbarLink>
                <Link href="/#why">Product</Link>
              </NavbarLink>
              <NavbarLink>
                <Link href="/about-us">About Us</Link>
              </NavbarLink>
              <NavbarLink>
                <Link href="/working-at-polar-signals">Careers</Link>
              </NavbarLink>
              <NavbarLink>
                <Link href="/blog">Blog</Link>
              </NavbarLink>
              <NavbarLink>
                <Link href="/#get-access">
                  <button type="button" className="cta">
                    Get Access
                  </button>
                </Link>
              </NavbarLink>
            </div>
          </div>
        </div>
      </NavbarContainer>
    </Popover>
  );
};

export default Navbar;
